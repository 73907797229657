import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class CustomerGroupProvider extends HttpRequest {
  findAll () {
    return this.get('/ticket')
  }

  findAllTicket () {
    return this.get('/ticket/dashboard/all')
  }

  findAllStatus (userId) {
    return this.get(`/ticket/status/all?userId=${userId}`)
  }

  findOne (id) {
    return this.get(`/ticket/${id}`)
  }

  findByCode (code) {
    return this.get(`/ticket/${code}/code`)
  }

  findByCustomerProfile (id, page = 1, limit = 10, query) {
    return this.get(`/ticket/customer-profile/${id}?page=${page}&limit=${limit}${generateQuery(query)}`)
  }

  createGroup (payload) {
    return this.post('/ticket', payload)
  }

  createInitial (userId) {
    return this.post(`/ticket/initial?userId=${userId}`)
  }

  createTicketByCustomer (customerId, userId) {
    return this.post(`/ticket/${customerId}/customer?userId=${userId}`)
  }

  duplicateTicket (payload) {
    return this.post('/ticket/duplicate', payload)
  }

  reOpenTicket (id, payload) {
    return this.post(`/ticket/${id}/re-open`, payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/ticket/${id}`, payload)
  }

  voidTicket (id) {
    return this.post(`/ticket/${id}/void`)
  }

  deleteGroup (id) {
    return this.delete(`/ticket/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/ticket?page=${page}&limit=${limit}`, query)
  }

  transferTicket (ticketId, agentId) {
    return this.post(`/ticket/${ticketId}/transfer`, {
      agentId
    })
  }
}

export default CustomerGroupProvider
